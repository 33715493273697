<script setup lang="ts">
const color = useColorMode()

useHead({
  meta: [{
    id: 'theme-color',
    name: 'theme-color',
    content: () => color.value === 'dark' ? '#222222' : '#ffffff',
  }],
})

function toggleDark() {
  color.preference = color.value === 'dark' ? 'light' : 'dark'
}
</script>

<template>
  <button @click="toggleDark">
    <Icon v-if="color.value === 'dark'" name="ic:outline-dark-mode" size="20" />
    <Icon v-else name="ic:outline-light-mode" size="20" />
  </button>
</template>
